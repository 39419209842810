// src/store/themeStore.js
import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';
import { useAuthStore } from './auth';

export const useThemeStore = defineStore('theme', () => {
    const authStore = useAuthStore();

    const isDarkMode = ref(true);
    const scoreTheme = ref('normal');

    const lightModeOptions = [
        { label: '라이트', value: 'normal' },
        { label: '다크', value: 'invert' },
        { label: '실내', value: 'darken' },
        { label: '어두운 방', value: 'darkness' }
    ];

    const darkModeOptions = [
        { label: '라이트', value: 'normal' },
        { label: '다크', value: 'invert' },
        { label: '실내', value: 'darken' },
        { label: '어두운 방', value: 'darkness' }
    ];

    const currentFilterOptions = computed(() =>
        isDarkMode.value ? darkModeOptions : lightModeOptions
    );

    const defaultFilter = computed(() =>
        isDarkMode.value ? 'normal' : 'normal'
    );

    const initializeThemes = (userThemes) => {
        if (userThemes) {
            isDarkMode.value = userThemes.isDarkMode;
            scoreTheme.value = userThemes.scoreTheme || defaultFilter.value;
        } else {
            const savedIsDarkMode = localStorage.getItem('isDarkMode');
            const savedScoreTheme = localStorage.getItem('scoreTheme');

            isDarkMode.value = savedIsDarkMode === null ? true : savedIsDarkMode === 'true';
            scoreTheme.value = savedScoreTheme || defaultFilter.value;
        }

        // 초기 테마 적용
        if (isDarkMode.value) {
            document.body.classList.remove('light-mode');
        } else {
            document.body.classList.add('light-mode');
        }
    };

    const toggleTheme = () => {
        isDarkMode.value = !isDarkMode.value;
    };

    const updateScoreTheme = (newTheme) => {
        scoreTheme.value = newTheme;
    };

    // isDarkMode와 scoreTheme을 별도로 감시
    watch(
        isDarkMode,
        (newIsDarkMode) => {
            if (authStore.isAuthenticated) {
                authStore.updateUserThemes({
                    isDarkMode: newIsDarkMode,
                    scoreTheme: scoreTheme.value
                });
            } else {
                localStorage.setItem('isDarkMode', newIsDarkMode);
            }

            if (newIsDarkMode) {
                document.body.classList.remove('light-mode');
            } else {
                document.body.classList.add('light-mode');
            }
        }
    );

    watch(
        scoreTheme,
        (newScoreTheme) => {
            if (authStore.isAuthenticated) {
                authStore.updateUserThemes({
                    isDarkMode: isDarkMode.value,
                    scoreTheme: newScoreTheme
                });
            } else {
                localStorage.setItem('scoreTheme', newScoreTheme);
            }
        }
    );

    // 인증 상태 변경 감시
    watch(
        () => authStore.isAuthenticated,
        (isAuthenticated) => {
            if (isAuthenticated) {
                initializeThemes(authStore.userThemes);
            } else {
                initializeThemes(null);
            }
        },
        { immediate: true }
    );

    return {
        isDarkMode,
        scoreTheme,
        toggleTheme,
        initializeThemes,
        updateScoreTheme,
        currentFilterOptions,
        defaultFilter
    };
});