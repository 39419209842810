import { defineStore } from 'pinia';
import { auth, db } from '../firebase';
import {
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile,
    sendPasswordResetEmail,
    deleteUser,
    reauthenticateWithCredential,
    EmailAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    reauthenticateWithPopup
} from 'firebase/auth';
import {
    doc,
    getDoc,
    setDoc,
    collection,
    query,
    where,
    getDocs,
    deleteDoc,
    Timestamp,
    updateDoc,
} from 'firebase/firestore';
import { useThemeStore } from './themeStore';
import { setUid, trackLogin, trackUserRetention, trackGoogleLoginError, trackGoogleLoginSuccess } from '@/analytics';
import router from '@/router';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null,
        userProfile: null,
        loading: true,
        error: null,
        themes: {
            isDarkMode: true,
            scoreTheme: 'invert',
            isCollapsed: false,
        },
        scoreSettings: {
            isPageMode: true,
            scoreFilter: 'invert',
            lastSelectedScore: {
                type: null,
                partName: null
            }
        },
        audioSettings: {
            instruments: [
                { id: 'master', name: 'Master', volume: 50 },
                { id: 'beat', name: '메트로놈', volume: 50 },
                { id: 'melody', name: '멜로디', volume: 50 },
                { id: 'drums', name: '드럼', volume: 50 },
                { id: 'guitar', name: '기타', volume: 50 },
                { id: 'bass', name: '베이스', volume: 50 },
                { id: 'piano', name: '키보드', volume: 50 },
                { id: 'etc', name: 'ETC', volume: 50 }
            ],
            midiEnabled: true,
            metronomeEnabled: true
        },
        metronomeSettings: {
            isPlayingEighth: false,
            prebeatEnabled: false,
            metronomeSound: 'click',
            prebeatSound: 'click',
            pitch: 50
        },
        currentRoomId: null,
        currentFetchedUID: null,
    }),

    actions: {
        async login(email, password) {
            console.log('로그인 시도 중...');
            this.loading = true;
            this.error = null;

            try {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                console.log('Firebase 로그인 성공:', userCredential);
                this.user = userCredential.user;
                await this.fetchUserProfile();
                await this.updateDisplayName();
                console.log('로그인 완료. 닉네임:', this.userProfile?.nickname || '익명');

                setUid(this.user.uid);
                trackLogin(this.user.uid);
                trackUserRetention();
                return true;
            } catch (error) {
                console.error("로그인 오류:", error);
                this.error = error.message;
                return false;
            } finally {
                this.loading = false;
                console.log('로그인 프로세스 완료');
            }
        },

        async logout() {
            try {
                const confirmed = window.confirm('정말 로그아웃하시겠습니까?');
                if (!confirmed) return;

                await signOut(auth);
                this.clearUserData();
            } catch (error) {
                this.error = error.message;
                console.error("로그아웃 오류:", error);
            }
        },

        async loginWithGoogle() {
            this.loading = true;
            this.error = null;

            try {
                const provider = new GoogleAuthProvider();

                console.log("Starting Google sign-in process");
                const result = await signInWithPopup(auth, provider);
                const user = result.user;

                console.log("Google sign-in result:", result);
                console.log("User object:", user);
                console.log("Additional User Info Profile:", result.additionalUserInfo?.profile);

                const email = user.email || (result.additionalUserInfo?.profile?.email) || (result._tokenResponse?.email) || null;
                console.log("확인된 사용자 이메일:", email);

                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                let isNewUser = false;

                if (!userDoc.exists()) {
                    console.log("Creating new user document");
                    const userData = {
                        email: email,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        timestamp: Timestamp.now(),
                        language: 'ko',
                        nickname: user.displayName || '익명',
                        scoreSettings: this.scoreSettings,
                        audioSettings: this.audioSettings,
                        metronomeSettings: this.metronomeSettings
                    };
                    console.log("New user data:", userData);
                    await setDoc(userDocRef, userData);
                    isNewUser = true;
                } else {
                    console.log("Updating existing user document");
                    const userData = userDoc.data();
                    console.log("Existing user data:", userData);
                    if (email && userData.email !== email) {
                        await updateDoc(userDocRef, { email: email });
                        console.log("Updated email in Firestore");
                    }
                }

                this.user = user;
                await this.fetchUserProfile();

                console.log("Final user profile:", this.userProfile);

                setUid(user.uid);
                trackLogin(user.uid);
                trackUserRetention();
                trackGoogleLoginSuccess(user.uid);

                return { success: true, isNewUser };
            } catch (error) {
                console.error("Google 로그인 오류:", error);
                this.error = error.message;
                trackGoogleLoginError(error);
                return { success: false, isNewUser: false };
            } finally {
                this.loading = false;
            }
        },

        async completeGoogleSignup(nickname, language, profileImageId, profileImageUrl) {
            if (!this.user) throw new Error('No authenticated user found');

            try {
                const userDocRef = doc(db, 'users', this.user.uid);
                await updateDoc(userDocRef, {
                    nickname,
                    language,
                    profileImageId,
                    profileImageUrl
                });

                this.userProfile = {
                    ...this.userProfile,
                    nickname,
                    language,
                    profileImageId,
                    profileImageUrl
                };

                router.push('/room');
            } catch (error) {
                console.error("Error completing Google signup:", error);
                throw error;
            }
        },

        clearUserData() {
            this.user = null;
            this.userProfile = null;
            this.error = null;
            this.themes = {
                isDarkMode: true,
                scoreTheme: 'invert',
                isCollapsed: false,
            };
            this.scoreSettings = {
                isPageMode: true,
                scoreFilter: 'invert',
                lastSelectedScore: {
                    type: null,
                    partName: null
                }
            };
            this.audioSettings = {
                instruments: [
                    { id: 'master', name: 'Master', volume: 50 },
                    { id: 'beat', name: '메트로놈', volume: 50 },
                    { id: 'melody', name: '멜로디', volume: 50 },
                    { id: 'drums', name: '드럼', volume: 50 },
                    { id: 'guitar', name: '기타', volume: 50 },
                    { id: 'bass', name: '베이스', volume: 50 },
                    { id: 'piano', name: '키보드', volume: 50 },
                    { id: 'etc', name: 'ETC', volume: 50 }
                ],
                midiEnabled: true,
                metronomeEnabled: true
            };
            this.metronomeSettings = {
                isPlayingEighth: false,
                prebeatEnabled: true,
                metronomeSound: 'click',
                prebeatSound: 'voice',
                pitch: 50
            };
            this.currentRoomId = null;
            this.currentFetchedUID = null;

            setUid('');
        },

        async checkAuth() {
            return new Promise((resolve) => {
                onAuthStateChanged(auth, async (user) => {
                    this.user = user;
                    if (user) {
                        setUid(user.uid);
                        await this.fetchUserProfile();
                        await this.updateDisplayName();
                    } else {
                        this.clearUserData();
                    }
                    this.loading = false;

                    const themeStore = useThemeStore();
                    if (this.isAuthenticated) {
                        themeStore.initializeThemes(this.themes);
                    } else {
                        themeStore.initializeThemes(null);
                    }

                    resolve(user);
                });
            });
        },

        async fetchUserProfile() {
            if (!this.user) {
                console.log("No authenticated user found");
                return;
            }
            if (this.currentFetchedUID === this.user.uid) {
                return;
            }
            try {
                console.log("Fetching user profile for UID:", this.user.uid);
                const docRef = doc(db, 'users', this.user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.userProfile = docSnap.data();

                    let needsUpdate = false;
                    const updates = {};

                    if (this.user.email && this.userProfile.email !== this.user.email) {
                        updates.email = this.user.email;
                        needsUpdate = true;
                    }
                    if (this.userProfile.displayName !== this.user.displayName) {
                        updates.displayName = this.user.displayName;
                        needsUpdate = true;
                    }
                    if (this.userProfile.photoURL !== this.user.photoURL) {
                        updates.photoURL = this.user.photoURL;
                        needsUpdate = true;
                    }

                    if (needsUpdate) {
                        console.log("Updating user profile in Firestore:", updates);
                        await updateDoc(docRef, updates);
                        Object.assign(this.userProfile, updates);
                    }

                    if (this.userProfile.themes) {
                        this.themes = { ...this.themes, ...this.userProfile.themes };
                    }
                    if (this.userProfile.scoreSettings) {
                        this.scoreSettings = { ...this.scoreSettings, ...this.userProfile.scoreSettings };
                    }
                    if (this.userProfile.audioSettings) {
                        this.audioSettings = { ...this.audioSettings, ...this.userProfile.audioSettings };
                    }
                    if (this.userProfile.metronomeSettings) {
                        this.metronomeSettings = { ...this.metronomeSettings, ...this.userProfile.metronomeSettings };
                    }
                    this.currentRoomId = this.userProfile.currentRoomId || null;

                } else {
                    console.log("User document does not exist in Firestore");
                    const email = this.user.email || (this.user.providerData[0]?.email) || null;
                    const userData = {
                        email: email,
                        displayName: this.user.displayName,
                        photoURL: this.user.photoURL,
                        timestamp: Timestamp.now(),
                        language: 'ko',
                        nickname: this.user.displayName || '익명',
                        scoreSettings: this.scoreSettings,
                        audioSettings: this.audioSettings,
                        metronomeSettings: this.metronomeSettings
                    };
                    console.log("Creating user document with data:", userData);
                    await setDoc(docRef, userData);
                    await this.fetchUserProfile();
                }
                this.currentFetchedUID = this.user.uid;
            } catch (error) {
                console.error("Error fetching user profile:", error);
                this.userProfile = null;
                this.currentRoomId = null;
            }
        },

        async updateDisplayName() {
            if (!this.user || !this.userProfile) return;
            try {
                if (this.user.displayName !== this.userProfile.nickname) {
                    await updateProfile(this.user, {
                        displayName: this.userProfile.nickname
                    });
                    console.log('DisplayName 업데이트됨:', this.userProfile.nickname);
                }
            } catch (error) {
                console.error("DisplayName 업데이트 오류:", error);
            }
        },

        async updateLastSelectedScore(type, partName) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'scoreSettings.lastSelectedScore': {
                        type,
                        partName
                    }
                });
                this.scoreSettings.lastSelectedScore = { type, partName };
                console.log('마지막 선택한 악보 정보 업데이트됨:', { type, partName });
            } catch (error) {
                console.error("마지막 선택한 악보 정보 업데이트 오류:", error);
            }
        },

        async updateAudioSettings(settings) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    audioSettings: settings
                });
                this.audioSettings = settings;
                console.log('오디오 설정 업데이트됨:', settings);
            } catch (error) {
                console.error("오디오 설정 업데이트 오류:", error);
            }
        },

        async updateMetronomeSettings(settings) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    metronomeSettings: settings
                });
                this.metronomeSettings = settings;
                console.log('메트로놈 설정 업데이트됨:', settings);
            } catch (error) {
                console.error("메트로놈 설정 업데이트 오류:", error);
            }
        },

        async resetPassword(email) {
            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('email', '==', email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    await sendPasswordResetEmail(auth, email);
                    return true;
                }
                return false;
            } catch (error) {
                console.error('비밀번호 재설정 오류:', error);
                throw error;
            }
        },

        async deleteAccount(password) {
            if (!this.user) return { success: false, message: "사용자가 로그인되어 있지 않습니다." };

            try {
                const userDoc = await getDoc(doc(db, 'users', this.user.uid));
                const userData = userDoc.data();
                if (userData.timestamp && userData.timestamp instanceof Timestamp) {
                    const creationTime = userData.timestamp.toDate();
                    const now = new Date();
                    const hoursSinceCreation = (now - creationTime) / (1000 * 60 * 60);
                    if (hoursSinceCreation < 24) {
                        return { success: false, message: `계정 생성 후 24시간이 지나지 않았습니다. ${Math.ceil(24 - hoursSinceCreation)}시간 후에 다시 시도해주세요.` };
                    }
                }

                const isGoogleUser = this.user.providerData.some(provider => provider.providerId === 'google.com');

                if (isGoogleUser) {
                    const provider = new GoogleAuthProvider();
                    await reauthenticateWithPopup(this.user, provider);
                } else {
                    const credential = EmailAuthProvider.credential(this.user.email, password);
                    await reauthenticateWithCredential(this.user, credential);
                }

                await deleteDoc(doc(db, 'users', this.user.uid));
                await deleteUser(this.user);

                this.clearUserData();
                return { success: true, message: "계정이 성공적으로 삭제되었습니다." };
            } catch (error) {
                console.error("계정 삭제 오류:", error);
                this.error = error.message;

                let errorMessage = "계정 삭제 중 오류가 발생했습니다.";
                if (error.code === 'auth/wrong-password') {
                    errorMessage = "비밀번호가 올바르지 않습니다.";
                } else if (error.code === 'auth/too-many-requests') {
                    errorMessage = "너무 많은 시도가 있었습니다. 잠시 후 다시 시도해주세요.";
                } else if (error.code === 'auth/network-request-failed') {
                    errorMessage = "네트워크 오류가 발생했습니다. 인터넷 연결을 확인해주세요.";
                } else if (error.code === 'auth/popup-closed-by-user') {
                    errorMessage = "Google 인증 팝업이 닫혔습니다. 다시 시도해주세요.";
                }

                return { success: false, message: errorMessage };
            }
        },

        async deleteGoogleAccount() {
            if (!this.user) return { success: false, message: "사용자가 로그인되어 있지 않습니다." };

            try {
                const isGoogleUser = this.user.providerData.some(provider => provider.providerId === 'google.com');
                if (!isGoogleUser) {
                    return { success: false, message: "이 계정은 Google 계정으로 로그인되지 않았습니다." };
                }

                const provider = new GoogleAuthProvider();
                await reauthenticateWithPopup(this.user, provider);

                await deleteDoc(doc(db, 'users', this.user.uid));
                await deleteUser(this.user);

                this.clearUserData();
                return { success: true, message: "Google 계정이 성공적으로 삭제되었습니다." };
            } catch (error) {
                console.error("Google 계정 삭제 오류:", error);
                this.error = error.message;

                let errorMessage = "Google 계정 삭제 중 오류가 발생했습니다.";
                if (error.code === 'auth/requires-recent-login') {
                    errorMessage = "보안상의 이유로 재로그인이 필요합니다. 로그아웃 후 다시 로그인해 주세요.";
                } else if (error.code === 'auth/popup-closed-by-user') {
                    errorMessage = "인증 팝업이 닫혔습니다. 다시 시도해 주세요.";
                } else if (error.code === 'auth/network-request-failed') {
                    errorMessage = "네트워크 오류가 발생했습니다. 인터넷 연결을 확인해 주세요.";
                } else if (error.code === 'auth/user-disabled') {
                    errorMessage = "이 계정은 비활성화되었습니다. 관리자에게 문의해 주세요.";
                }

                return { success: false, message: errorMessage };
            }
        },

        async updateUserThemes({ isDarkMode, scoreTheme, isCollapsed }) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'themes.isDarkMode': isDarkMode,
                    'themes.scoreTheme': scoreTheme,
                    'themes.isCollapsed': isCollapsed,
                });
                this.themes = { isDarkMode, scoreTheme, isCollapsed };
                console.log('사용자 테마 업데이트됨:', this.themes);
            } catch (error) {
                console.error("사용자 테마 업데이트 오류:", error);
            }
        },

        async updateIsCollapsed(isCollapsed) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'themes.isCollapsed': isCollapsed,
                });
                this.themes.isCollapsed = isCollapsed;
                console.log('isCollapsed 업데이트됨:', isCollapsed);
            } catch (error) {
                console.error("isCollapsed 업데이트 오류:", error);
            }
        },

        async updateUserScoreSettings({ isPageMode, scoreFilter }) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'scoreSettings.isPageMode': isPageMode,
                    'scoreSettings.scoreFilter': scoreFilter,
                });
                this.scoreSettings = {
                    ...this.scoreSettings,
                    isPageMode,
                    scoreFilter
                };
                console.log('사용자 악보 설정 업데이트됨:', this.scoreSettings);
            } catch (error) {
                console.error("사용자 악보 설정 업데이트 오류:", error);
            }
        },

        async updateCurrentRoom(roomId) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, { currentRoomId: roomId });
                this.currentRoomId = roomId;
                console.log('사용자의 현재 방 정보 업데이트됨:', roomId);
            } catch (error) {
                console.error("사용자의 현재 방 정보 업데이트 오류:", error);
            }
        },
    },

    getters: {
        isAuthenticated: (state) => !!state.user,
        userNickname: (state) => state.userProfile?.nickname || '익명',
        currentUser: (state) => state.user,
        userThemes: (state) => state.themes,
        userScoreSettings: (state) => state.scoreSettings,
        userAudioSettings: (state) => state.audioSettings,
        userMetronomeSettings: (state) => state.metronomeSettings,
        isInRoom: (state) => !!state.currentRoomId,
    }
});