import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'

function parseScoreSettings(settingsStr) {
    if (!settingsStr) return null;
    try {
        const decoded = decodeURIComponent(settingsStr);
        return JSON.parse(decoded);
    } catch (e) {
        console.error('Error parsing score settings:', e);
        return null;
    }
}

function parseMidiSettings(settingsStr) {
    if (!settingsStr) return null;
    try {
        const decoded = decodeURIComponent(settingsStr);
        return JSON.parse(decoded);
    } catch (e) {
        console.error('Error parsing midi settings:', e);
        return null;
    }
}

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: () => import('@/screens/LandingScreen.vue'),
        meta: {
            title: 'FEAT - 무료 악보 & 악기 연습 툴',
            description: 'FEAT의 무료 악보와 싱크로나이즈드 메트로놈을 이용해 멤버들과 함께 합주하고 악기를 연습해보세요.'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/screens/LoginScreen.vue'),
        meta: {
            title: '로그인 | KAMO ID',
            description: 'KAMO ID로 로그인하여 FEAT를 이용하세요.'
        }
    },
    {
        path: '/find-id',
        name: 'FindID',
        component: () => import('@/screens/FindIDScreen.vue'),
        meta: {
            title: '비밀번호 찾기 | KAMO ID',
            description: '비밀번호를 잊어버리셨나요?'
        }
    },
    {
        path: '/room',
        name: 'Room',
        component: () => import('@/screens/RoomScreen.vue'),
        meta: {
            title: '방 생성 | FEAT',
            description: '합주실을 생성하고 멤버들과 함께 합주해보세요.',
            requiresAuth: true
        }
    },
    {
        path: '/main/:roomId?',
        name: 'Main',
        component: () => import('@/screens/MainScreen.vue'),
        props: true,
        meta: {
            title: '합주실 | FEAT',
            description: 'FEAT의 무료 악보와 싱크로나이즈드 메트로놈을 이용해 멤버들과 함께 합주하고 악기를 연습해보세요.',
            requiresAuth: true
        }
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('@/screens/SignupScreen.vue'),
        meta: {
            title: '회원가입 | KAMO ID',
            description: 'KAMO ID를 생성하고 다양한 기능을 이용해보세요.'
        }
    },
    {
        path: '/setacc',
        name: 'setacc',
        component: () => import('@/screens/SettingAccount.vue'),
        meta: {
            requiresAuth: true,
            title: '계정 설정 | KAMO ID',
            description: 'KAMO ID를 설정해 보세요.'
        }
    },
    {
        path: '/temple',
        name: 'temple',
        component: () => import('@/screens/MohapTemple.vue'),
        meta: {
            requiresAuth: true,
            title: '이곳에 허락없이 들어온 자 돌돔의 심판을 받을 것이니'
        }
    },
    {
        path: '/setgoogle',
        name: 'GoogleSignupComplete',
        component: () => import('@/screens/GoogleSignupComplete.vue'),
        meta: {
            requiresAuth: true,
            allowIncompleteProfile: true,
            title: 'Google로 회원가입 | KAMO ID'
        }
    },
    {
        path: '/Board',
        name: 'Board',
        component: () => import('@/screens/BoardScreen.vue'),
        meta: {
            requiresAuth: true,
            title: '공지사항 | FEAT'
        }
    },
    {
        path: '/scores/:id',
        name: 'SeoScore',
        component: () => import('@/screens/SeoScreen.vue'),
        meta: {
            public: true,
            title: '무료 악보 | FEAT',
            description: 'FEAT가 제공하는 무료 악보로 멤버들과 합주하고, 악기를 연습해보세요.'
        },
        props: route => {
            const defaultScoreSettings = {
                filter: 'normal',
                prebeat: 0
            };

            const defaultMidiSettings = {
                master: 50,
                beat: 50,
                melody: 50,
                drums: 50,
                guitar: 50,
                bass: 50,
                piano: 50,
                etc: 50,
                midiEnabled: true,
                metronomeEnabled: true
            };

            const scoreSettings = parseScoreSettings(route.query.scoreSettings) || defaultScoreSettings;
            const midiSettings = parseMidiSettings(route.query.midiSettings) || defaultMidiSettings;
            const isDarkMode = route.query.isDarkMode === 'false' ? false : true;

            return {
                id: route.params.id,
                scoreSettings,
                midiSettings,
                isDarkMode,
                rawQuery: route.query
            };
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    await authStore.checkAuth();

    // 페이지 타이틀 및 메타 태그 업데이트
    if (to.meta.title) {
        document.title = to.meta.title;
        let ogTitle = document.querySelector('meta[property="og:title"]');
        if (!ogTitle) {
            ogTitle = document.createElement('meta');
            ogTitle.setAttribute('property', 'og:title');
            document.head.appendChild(ogTitle);
        }
        ogTitle.content = to.meta.title;
    }

    if (to.meta.description) {
        let description = document.querySelector('meta[name="description"]');
        if (!description) {
            description = document.createElement('meta');
            description.setAttribute('name', 'description');
            document.head.appendChild(description);
        }
        description.content = to.meta.description;
    }

    // 기존 조건: setacc에서 특정 페이지로 이동 제한
    if (from.name === 'setacc' && !['Room', 'Main', 'Login', 'temple'].includes(to.name)) {
        next(false);
        return;
    }

    // 로그인 페이지는 언제나 접근 가능
    if (to.name === 'Login') {
        next();
        return;
    }

    // /scores/:id 페이지는 로그인 없이 접근 가능
    const isSeoScoreRoute = to.name === 'SeoScore';

    // 사용자 이메일 검증 (로그인 상태라면)
    const userEmail = authStore.userProfile?.email || '';

    // 로그인하지 않았거나, 로그인된 계정의 이메일이 @kamo.kr로 끝나지 않는 경우
    if (!authStore.isAuthenticated || !userEmail.endsWith('@kamo.kr')) {
        if (isSeoScoreRoute) {
            next();
        } else {
            next({ name: 'SeoScore', params: { id: 1 } });
        }
        return;
    }

    // 모든 조건이 만족되면 정상 이동
    next();
});

export default router;